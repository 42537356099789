<template>
	<div class="invite-download-page">

		<p style="text-align: center;padding: 70px">
			<van-image
				fit="contain"
				:src="resourceURL + 'images/logo_white.png'"
				width="160"
			/>
		</p>

		<van-row class="buttons">
			<van-col span="24" style="margin-bottom:35px;color:#FFFFFF;text-align:center;line-height:200%;font-size: 17px">
				{{translate('congratulations_registration')}}<br />{{translate('can_download_app')}}
			</van-col>
			<van-col span="24">
				<van-button type="default" block round color="#FE6D01" @click="nextStep">{{translate('invite_go_download')}}</van-button>
			</van-col>
		</van-row>
	</div>
</template>

<script>
	export default {
		name: 'invite_download',
		data() {
			return {
				formItem: {
					code: "",
					name: "",
					idcode: "",
					email: "",
				},

				token: "",

				resourceURL: this.$axios.defaults.resourceURL,
				
			}
		},
		
		methods: {
			
			nextStep() {
				
				this.$router.replace('/invite/download')
			
			}
		},
	}
</script>